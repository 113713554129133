.button__menu {
  width: 90%;
  height: 3em;
  margin-left: 0.75em;
  text-align: left;
  padding-left: 1.5em;
  border-radius: 0.5em;
  transition: 200ms;
  font-size: 1em;
  color: white;
}

.button__menu:hover {
  background-color: rgba(255, 255, 255, 1);
  color: #13767B;
  transition: 200ms;
}

.button__transparent {
  border: none;
  background-color: transparent;
}

.button__content {
  display: flex;
  align-items: center;
}

.button__content > p {
  margin-left: 0.5em;
  margin-top: 0.25em
}