.form-page-wrapper {
    display: flex;
    justify-content: center;
    width: '100%';
}

div.form-wrapper {
    display: flex;
    flex-direction: column;
    width: '100%';
    max-width: 35em;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
    border-radius: 0.25em;
    padding: 2em;
}

.form-buttons-wrapper {
    display: flex;
    justify-content: space-evenly;
    margin: 1em
}

.form-header {
    margin: 0.5em 0;
}

.form-description {
    margin: 0.75em 0;
}