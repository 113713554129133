.menu {
    display: flex;
    flex-direction: column;
    position: 'fixed';
    top: 0;
    -webkit-box-shadow: 3px 0px 5px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 3px 0px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 3px 0px 5px 0px rgba(0, 0, 0, 0.1);
    height: 100%;
    width: 100%
}

.menu > .contact-info {
    color: white;
    z-index: 100;
}

.contact-info > div > a {
    text-decoration: none;
    color: white;
}