.multi-select-wrapper {
    height: 10em;
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 0.25em;
    border: 1px solid rgb(195, 195, 195);
}

.multi-select-item {
    display: flex;
    align-items: center;
    height: 2.5em;
    width: 100%;
    background-color: white;
    padding-left: 0.25em;
}

.multi-select-item:hover {
    background-color: rgb(233, 233, 233);
}

.multi-select-item-name {
    width: 50%;
    padding-left: 1em;
}
.multi-select-item-icon {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 1.5em;
    justify-self: flex-end;
    margin-left: auto;
    margin-right: 1.5em;
}

.multi-select-item-icon:hover {
    color: rgb(78, 78, 78);
    cursor: pointer;
}