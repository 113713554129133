.scatter-chart-wrapper {
    display: grid;
    grid-template-columns: 2em auto 2em;
    grid-template-rows: auto 2em;
}

.scatter-chart-anxiety-legend {
    grid-column: 1/2;
    display: flex;
    align-items: center;
}

.scatter-chart-anxiety-legend > p {
    transform: rotate(-90deg);
    white-space: nowrap;
    width: 100%;
}

.scatter-chart-time-legend {
    grid-column: 2/3;
    grid-row: 2/3;
    display: flex;
    justify-content: center;
}

.scatter-plot {
    grid-column: 2/3;
}