.content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.home-title {
    margin-bottom: 1em;
}

.content-wrapper > p {
    margin-bottom: 2em;
    margin-right: 1.25em;
}

.onboarding-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
}
.onboarding-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 48em;
    height: 35em;
}

.onboarding-item {
    display: grid;
    grid-template-columns: 3.5em 1fr;
    height: 100%;
    width: 22em;
    height: 17em;
    padding: 1em;
}

.onboarding-item-right {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 3em 1fr;
}

.onboarding-icon {
    width: 2.5em;
    height: 2.5em;
}

.onboarding-item-header{
    display: flex;
    align-items: center;
}

.onboarding-item-header > h1, h2, h3, p {
    margin: 0;
    padding: 0
}

.onboarding-item-body {
    display: grid;
    grid-template-rows: 1fr 4em;
}

.onboarding-item-body > p {
    padding-top: 0.5em;
    padding-bottom: 1.5em;
}

.onboarding-item-body > a {
    width: 12.5em;
    height: 2.75em;
}

button.onboarding-button:hover {
    cursor: pointer;
    opacity: 0.8
}