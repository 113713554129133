.heatmap-wrapper {
    display: grid;
    grid-template-columns: 2em 1fr;
    grid-template-rows: 1fr 2em;
}
.time-legend {
    display: flex;
    align-items: center;
    grid-row: 1/2;
    grid-column: 1/2;
}
.time-legend > p {
    transform: rotate(-90deg);
    padding-bottom: 5em;
    white-space: nowrap;
}
.ylabels-map {
    display: flex;
    grid-row: 1/2;
    grid-column: 2/3;
    overflow-x: auto;
    overflow-y: hidden;
}
.date-legend {
    display: flex;
    justify-content: center;
    grid-row: 2/3;
    grid-column: 2/3;
    padding-top: 1em;
}

.heatmap-container {
    display: flex;
    height: auto;
    padding: 0 0 1em 0.5em;
    overflow-x: auto;
    overflow-y: hidden;
    min-width: 18em;
}

.heatmap-container::-webkit-scrollbar {
    width: 1em;
  }
.heatmap-container::-webkit-scrollbar-track {
    background-color:  rgba(0, 0, 0, 0.1);
    border-radius: 1em;
  }
.heatmap-container::-webkit-scrollbar-thumb {
    background-color: #13767B;
    border-radius: 1em;
  }


.heatmap-x-axis-item {
    display: grid;
    grid-template-rows: auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto;
    grid-template-columns: auto;
    width: 2.1em;
    height: 100%;
    background-color: rgb(255, 255, 255);
}

.heatmap-x-axis-item-label {
    display: flex;
    justify-content: center;
    align-items: top;
    position: relative;
    margin-top: 0;
    width: 100%;
    height: 4em;
    color: #525252; 
}

.heatmap-y-axis-labels-container {
    display: grid;
    grid-template-rows: auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto;
    height: 1em;
    width: 3em;
}

.heatmap-y-axis-label-container {
    padding: 0.08em;
    text-align: right;
    width: auto;
    height: auto;
    color: #525252;
}

.heatmap-x-axis-item-block-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 2em;
    padding: 0.08em;
}

.heatmap-x-axis-item-block-inner:hover {
    opacity: 0.7;
}

.heatmap-x-axis-item-block-inner {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
}

.gradient-legend-wrapper {
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.gradient-legend {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    border-radius: .25em;
    width: 80%;
    max-width: 40em;
    height: 2em;
    background-image: linear-gradient(to right, #224B06 , #3B840B, #85B83D, #FEE100, #F26418, #ED351D, #A30000)
}

.gradient-legend-label {
    display: flex;
    align-items: center;
    color: white;
    font-weight: bold;
}

.left {
    padding-left: 1em;
}

.right {
    padding-right: 1em;
    justify-content: right;
}

.gradient-legend-info {
    display: flex;
    align-items: center;
    padding-top: 1em;
    justify-content: center;
}

.gradient-legend-info > p {
    margin-right: 0.5em;
}

.tooltip-info {

}

.tooltip-info > p {
    font-size: 1.25em;
    padding: 1em;
}