@font-face {
    font-family: 'Rubik';
    font-style: normal;
    src: url('./Rubik-VariableFont_wght.ttf');
}

@font-face {
    font-family: 'Rubik-Italic';
    font-style: italic;
    src: url('./Rubik-Italic-VariableFont_wght.ttf');
}

.Font-Rubik {
    font-family: 'Rubik';
}

.Font-Rubik-Italic {
    font-family: 'Rubik-Italic';
}
